import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import CmpnyLogoImg from '../../imgs/company-logo.png';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ffffff',
        },
    },
});


const Header = (props) => {
    const mainMenu=[
        {
            label:"HR",
            path:"/hr",
            isActive:window.location.pathname=="/hr",
            id:"hr"
        }, {
            label:"Client",
            path:"/client",
            isActive:window.location.pathname=="/client",
            id:"client"
        },
        {
            label:"Project",
            path:"/project",
            isActive:window.location.pathname=="/project",
            id:"project"
        },        
        {
            label:"Trip",
            path:"/trip",
            isActive:window.location.pathname=="/"||window.location.pathname=="/trip",
            id:"trip"
        }
    ]
    const { toggleAddResume } = props;
    const [userRole, setUserRole] = React.useState("");
    const [toggleDraw, setToggleDraw] = React.useState(false);
    const [menuItems,setMenuItems]=React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isMenuOpen,setIsMenuOpen]=React.useState(false);
    const open = Boolean(anchorEl);
    
    React.useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const tokenArr = JSON.parse(token);
            if (tokenArr && tokenArr.role)
                setUserRole(tokenArr.role);
            if(tokenArr && tokenArr.permissions){
                const menu=   mainMenu.filter((item)=>(tokenArr.permissions).includes(item.id))
                setMenuItems(menu);
            }
        }
    }, []);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      
    const logoutApp = () => {
        if(window.confirm("Please confirm logout")){
        localStorage.removeItem("token");
        window.location.reload();
        }
    }
    const toggleSettings = () => {
        setToggleDraw(!toggleDraw);
    }
    const renderMenu = (
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        >
          <MenuItem component={Link} to="/password-reset">Change password</MenuItem>
          <MenuItem onClick={logoutApp} component={Link} to="/">Logout</MenuItem>
        </Menu>
      );
    return (
        <React.Fragment>

            <ThemeProvider theme={darkTheme} className={"hiheader"}>
                <AppBar
                    position="fixed"
                    className="main-header"

                >
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div">
                        <Link to="/"  className={'logohead'}>     <img src={CmpnyLogoImg} width="155px" />  </Link>
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            {menuItems.map((item,index)=>(
                                <Button variant="text" key={index}  className={item.isActive?"_menu-active":''}  component={Link} to={item.path}  disableElevation>
                                {item.label}
                            </Button>
                            ))}
                         
                            <Button variant="text" disableElevation onClick={handleClick} >
                                Logout
                            </Button>
                        </Box>
                        {renderMenu}
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
            
        </React.Fragment >
    )
}
export default Header;