import React,{ useState } from "react";
import dayjs from "dayjs";
import * as weekOfYear from "dayjs/plugin/weekOfYear";
import * as weekYear from "dayjs/plugin/weekYear";
import * as isBetween from "dayjs/plugin/isBetween";
import * as isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import { Avatar, Chip, Popover, Tooltip, Typography } from "@mui/material";
import { _BASE_URL } from "../../Config/Constants";
const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };
  
const TripYearCal=({ tripList,
     currentYear, 
    handleSingleUser,
    scrollToday,
    tripListInactive,
    userList })=>{
    dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  dayjs.extend(isBetween);
  dayjs.extend(isoWeeksInYear);
  const [yearMonths,setYearMonths]=useState([]);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [img, setImg] = useState("");
  React.useEffect(() => {    
    const dateHead=[
        {
            title:"JAN "+currentYear,
            weekCount:5,
            clStart:currentYear+"-01-"
        },
        {
            title:"FEB "+currentYear,
            weekCount:isLeapYear(currentYear)?5:4,
            clStart:currentYear+"-02-"            
        },
        {
            title:"MAR "+currentYear,
            weekCount:5,
            clStart:currentYear+"-03-"   
        },
        {
            title:"APR "+currentYear,
            weekCount:5,
            clStart:currentYear+"-04-"   
        },
        {
            title:"MAY "+currentYear,
            weekCount:5,
            clStart:currentYear+"-05-"   
        },
        {
            title:"JUN "+currentYear,
            weekCount:5,
            clStart:currentYear+"-06-"   
        },
        {
            title:"JUL "+currentYear,
            weekCount:5,
            clStart:currentYear+"-07-" 
        },        
        {
            title:"AUG "+currentYear,
            weekCount:5,
            clStart:currentYear+"-08-" 
        },        
        {
            title:"SEP "+currentYear,
            weekCount:5,
            clStart:currentYear+"-09-" 
        },
        {
            title:"OCT "+currentYear,
            weekCount:5,
            clStart:currentYear+"-10-" 
        },{
            title:"NOV "+currentYear,
            weekCount:5,
            clStart:currentYear+"-11-" 
        },
        {
            title:"DEC "+currentYear,
            weekCount:5,
            clStart:currentYear+"-12-" 
        },
    ];
    setYearMonths(dateHead);
  }, [currentYear]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const singleTripOpen=(e,mem,trip)=>{
    e.preventDefault();
    e.stopPropagation();
    handleSingleUser(mem,trip)
  }
  const getUserRow=(trips,item,tripArrType)=>{
    let Weeks=60;
    let hFlag=false;
  const row=Array.from(Array(Weeks).keys()).map((week, index) =>{    
    const heightArr=[0,18];
    let tripData=[];
    let innerWidth=0;
    if(trips["week-" + (week+1)])
     tripData= trips["week-" + (week+1)].map((tr) => {
      hFlag = !hFlag;
      innerWidth=40*(Number(tr.intervalWeek)-(Number(tr.onwardJourney)+Number(tr.returnJourney)));
      innerWidth=innerWidth<0?0:innerWidth;
      return (
        <Tooltip title={`${tr.projectName} | ${tr.type}`} arrow>
          <div
            className={"_trip-dayx "}
            style={{ backgroundColor: tr.color+"80",width:(40*tr.intervalWeek)+'px',height:"18px",top:(hFlag?heightArr[0]:heightArr[1])+"px"}}
            onClick={e=>{
              tripArrType=='active'? singleTripOpen(e,tripList[item],tr): singleTripOpen(e,tripListInactive[item],tr)
            }}

          >
            <div  style={{ 
              width:innerWidth+'px',
              background:tr.color,
              position:"absolute",
              left:"0px",
              height:"18px",
              content:" "
,top:(hFlag?heightArr[0]:heightArr[1])+"px"
          }}></div>
          <span style={{position:'absolute',left:"2px"  ,  top: "-3px",zIndex:"1000",background:"transparent"}}>
          {`${tr.projectName}`}
          </span>
            
          </div>
        </Tooltip>
      );
    });
       return <td key={"tweekh_" + week + index} style={{minWidth:"40px"}}>
        {tripData} 
      </td>
  })
    return row;
  }
  const handleClose = () => {
    setAnchorEl(null);
    setImg("");
  };
  return <div
  style={{ width: "100%", overflowX: "auto", maxHeight: "80vh" }}
  className={"_table_wrap"}
  id="wrapperCal"
> <table className={"_cal_table table-cl"}>
            <thead>
                <th>
                    {currentYear}
                </th>
                {yearMonths.map(head=><th key={head.title} colSpan={head.weekCount}>{head.title}</th>)}
                <th></th>
            </thead>
            <tbody>
                <tr>
            <td colSpan={61}  className="inactive-trips">
               
                <span style={{
                  position: "sticky",
                  left: 20,
                  zIndex: 1200,
                }}>Active trips</span>
              </td>
              </tr>
            {Object.keys(tripList).map((item) => (
            <tr key={item}>
              <td
                align="left"
                style={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1200,
                }}
                className={"brd_blk name-td headcol"}
              >
                <Chip className="nobg" 
                label={tripList[item]["name"]+" "+tripList[item]["surName"]} 
                onClick={(e)=>{handleClick(e);setImg(tripList[item]["photo"]) } }
  avatar={<Avatar alt="Natacha" src={_BASE_URL+'/'+ tripList[item]["photo"]} />}
                />
              </td>
              {
                getUserRow(tripList[item]["trips"],item,"active")
              }
            </tr>
            
          ))}
          <tr>
            <td colSpan={61} className="inactive-trips">
            <span style={{
                  position: "sticky",
                  left: 20,
                  zIndex: 1200,
                }}>Inactive trips</span>
              </td>
              </tr>
              {Object.keys(tripListInactive).map((item) => (
            <tr key={item}>
              <td
                align="left"
                style={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1200,
                }}
                className={"brd_blk name-td headcol"}
              >
                <Chip className="nobg" 
                label={tripListInactive[item]["name"]+" "+tripListInactive[item]["surName"]} 
                onClick={(e)=>{handleClick(e);setImg(tripListInactive[item]["photo"]) } }
  avatar={<Avatar alt="Natacha" src={_BASE_URL+'/'+ tripListInactive[item]["photo"]} />}
                />
              </td>
              {
                getUserRow(tripListInactive[item]["trips"],item,"inactive")
              }
            </tr>
          ))}
                </tbody>
                <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          <img src={_BASE_URL + "/" + img} width={100} />
        </Typography>
      </Popover>
  </table>
  </div>
}

export default TripYearCal; 