import React from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Header from "../Includes/Header";
import { _TRIP_PATH } from "../../Config/Constants";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import SettingsIcon from '@mui/icons-material/Settings';
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import "./trip.css";
import AddTrip from "./addTrip";
import TripCalendar from "./TripCalendar";
import TripCalendarYear from "./TripCalendarYear";
import UserTrip from "./UserTrip";
import ChevronRightTwoToneIcon from "@mui/icons-material/ChevronRightTwoTone";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import TripFilter from "./TripFilter";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
const Trip = (props) => {
  const [singleTrip, setSingleTrip] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tripList, setTripList] = React.useState([]);
  const [tripListRemote, setTripListRemote] = React.useState([]);
  const [triptypeList, setTriptypeList] = React.useState([]);
  const [calYear, setCalYear] = React.useState(2023);
  const [calType, setCalType] = React.useState(1);
  const [singleUser, setSingleUser] = React.useState([]);
  const [addTrip, setAddTrip] = React.useState(false);
  const [userFilter, setUserFilter] = React.useState({});
  const [userList, setUserList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page,setPage] = React.useState(1);
  React.useEffect(() => {
    getTripList();
    getTriptypeList();
  }, []);
 const loadNextPage=()=>{
  if(!loading){
      setPage(page+1);
      getTripList();
  }
 }
  const getTripList = (crPage=false) => {
    setSingleUser([]);
    setOpenDialog(false);
    setLoading(true)
    axios
      .get(_TRIP_PATH + "/getTrip?page="+page)
      .then((response) => {
        const trRm=[...tripListRemote,...response.data.tripList];
        setTripListRemote(trRm);       
        setAddTrip(false);
        setTripList(trRm);
        const ulist = trRm.map((item) => ({
          id: item.supervisor,
          name: item.supervisorName,
        }));
        setUserList(ulist);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  };
  const getTriptypeList = () => {
    axios
      .get(_TRIP_PATH + "/getTriptype")
      .then((response) => {
        setTriptypeList(response.data.typeList);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      const param = { id: id.id };
      axios
        .post(_TRIP_PATH + "/deleteTrip", param)
        .then((response) => {
          getTripList();
          setOpenDialog(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleEdit = (id) => {
    axios
      .get(_TRIP_PATH + "/getTrip/" + id)
      .then((response) => {
        // console.log("responseresponse", response);
        if (response.data.trip) {
          setOpenDialog(true);
          setSingleTrip(response.data.trip);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSingleUser = (user,trip) => {
    if(trip){
      setSingleTrip(trip);
      alert(2333)
    }
    setSingleUser(user);
  };
  const applyFilter = (filter) => {
    let trips = [...tripListRemote];
    if (filter.project.length > 0) {
      trips = trips.filter(
        (trip) => filter.project.indexOf(trip.project) != -1
      );
    }
    if (filter.client.length > 0) {
      trips = trips.filter(
        (trip) => filter.client.indexOf(trip.clientId) != -1
      );
    }
    if (filter.triptype.length > 0) {
      trips = trips.filter(
        (trip) => filter.triptype.indexOf(trip.tripType) != -1
      );
    }
    
    setTripList(trips);
    const ulist = trips.map((item) => ({
      id: item.supervisor,
      name: item.supervisorName,
    }));
    setUserList(ulist);
  };
  const JSONToCSVConvertor=() =>{
    
    const JSONData=[...tripListRemote].map(item=>{
      return {
       // "Client":item.clientName,
        "Project":item.projectName,
        "Supervisor":item.supervisorName+" "+item.supervisorSurname,
        "Travel Type":item.travelType,
        "From date":item.fromDate,
        "To date":item.toDate,
        "Description":item.description,
        "Onward Journy":item.onwardJourney,
        "Return Journy":item.returnJourney,
        "Country":item.countryName,
      }
    })
    const ReportTitle="";
    const ShowLabel=true;
    let arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = '';      
    CSV += ReportTitle + '';
    if (ShowLabel) {
        let row = "";
        for (let index in arrData[0]) {
            row += index + ',';
        }
        row = row.slice(0, -1);
        CSV += row + '\r\n';
    }
    for (let i = 0; i < arrData.length; i++) {
        let row = "";
        for (let index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
    }
    if (CSV == '') {        
        alert("Invalid data");
        return;
    }  
    let fileName = "MyReport_";
    fileName += ReportTitle.replace(/ /g,"_");   
    let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    let link = document.createElement("a");    
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
  return (
    <React.Fragment>
      <Header />
      <Container
        maxWidth="100%"
        className="settings-container"
        style={{ marginTop: "70px" }}
      >
        <Grid container>
          <Grid item sm={5}>
            <h3 className='pagehead'>Trip Schedules</h3>
          </Grid>
          <Grid item sm={5} className="get_single_op_token alignright">
            
            <span >
              <TripFilter applyFilter={applyFilter} triptypeList={triptypeList} />
            </span >
            <span className="_tr_year_block">
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setCalYear(calYear - 1)}
            >
              <KeyboardArrowLeftTwoToneIcon />
            </IconButton>
            {calYear}
            <IconButton 
              size="small"
              color="primary"
              onClick={() => setCalYear(calYear + 1)}
            >
              <ChevronRightTwoToneIcon />
            </IconButton>
            </span>
            <ButtonGroup
              aria-label="Disabled elevation buttons"
              size="small" className="cale"
            >
              <Button onClick={() => setCalType(1)}  variant={calType==1?"contained":"outlined"}>
                Month
              </Button>
              <Button onClick={() => setCalType(2)} variant={calType==2?"contained":"outlined"}>
                Year
              </Button>
            </ButtonGroup>
            
            </Grid>
            <Grid item sm={2} className="get_single_op_token alignright">
            <IconButton  className="_tr_add_btn btnmgtop" onClick={()=>window.location.href="./triptype"} style={{color:'#1a588c'}} fontSize={"large"} >
                    <SettingsIcon />
                  </IconButton>  
            <IconButton 
              className="_tr_add_btn btnmgtop"
                  variant="outlined"
                  onClick={(e) => setAddTrip(true)} style={{color:'#1a588c'}} fontSize={"large"}
                >
                  <AddBoxRoundedIcon />
                </IconButton>
                <IconButton  className="_tr_add_btn btnmgtop"  onClick={JSONToCSVConvertor} style={{color:'#1a588c'}} fontSize={"large"}>
                  <FileDownloadIcon/>
                  </IconButton>

          </Grid>
          <Grid item sm={12}>
            {calType == 1 ? (
              <TripCalendar
                handleSingleUser={handleSingleUser}
                currentYear={calYear}
                tripList={tripList}
                tripTypes={triptypeList}
                loading={loading}
                loadNextPage={loadNextPage}
              />
            ) : (
              <TripCalendarYear
                handleSingleUser={handleSingleUser}
                currentYear={calYear}
                tripList={tripList}
                tripTypes={triptypeList}
              />
            )}
            
            {(singleUser && singleUser.length) || addTrip ? (
              <UserTrip
                singleTripSelected={singleTrip}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                getTripList={getTripList}
                triptypeList={triptypeList}
                userTrips={singleUser}
                addTrip={addTrip}
                currentYear={calYear}
                closeDialog={() => {
                  setSingleUser([]);
                  setAddTrip(false);
                }}
              />
            ) : null}
          </Grid>
        </Grid>
  
      </Container>
    </React.Fragment>
  );
};

export default Trip;
