import { Avatar, Chip, Tooltip } from "@mui/material";
import { _BASE_URL } from "../../../Config/Constants";
import React from "react";
const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };
 const TripRowComponent = ({ item, trip,handleClick,currentYear,singleTripOpen,dayNumber,crYear,tripArrType }) => {
    const [img, setImg] = React.useState("");
    const getUserRow=React.useMemo(()=>{
        const trips=trip["trips"];
        let Days=365;
        if(isLeapYear(currentYear)) {
          Days=366;
        } 
        let hFlag=false;
      const row=Array.from(Array(Days).keys()).map((day, index) =>{    
          
        let tripData=[];
        let innerWidth=0;
        const heightArr=[0,18];
        
        if(trips["day-" + day])
         tripData= trips["day-" + day].map((trRow) => {
          hFlag = !hFlag;
          const tr={...trRow,interval:(trRow.interval===0?1:trRow.interval)};
          innerWidth=40*(Number(tr.interval)-(Number(tr.onwardJourney)+Number(tr.returnJourney)));
          innerWidth=innerWidth<0?0:innerWidth;
          return (
            <Tooltip title={`${tr.projectName} | ${tr.type}`} arrow>
              <div
                className={"_trip-dayx "}
                style={{ backgroundColor: tr.color+"80",width:(40*tr.interval)+'px',height:"18px",top:(hFlag?heightArr[0]:heightArr[1])+"px"}}
                onClick={e=>{
                  tripArrType==='active'? singleTripOpen(e,trip,tr): singleTripOpen(e,trip,tr)
                }
    
                 
                }
              >
                <div  style={{ 
                  width:innerWidth+'px',
                  background:tr.color,
                  position:"absolute",
                  left:(40*tr.onwardJourney)+"px",
                  height:"17px",
                  content:" "
    
              }}></div>
              <span style={{position:'absolute',left:"2px",  top: "-3px",zIndex:"1000",background:"transparent"}}>
              {`${tr.projectName}`}
              </span>
                
              </div>
            </Tooltip>
          );
        });
           return <td key={"th_" + day + index} className={(dayNumber === (day+1) && crYear===currentYear)?"   _cl_today":""}>
            {tripData} 
          </td>
      })
        return row;
      },[trip,crYear,currentYear,dayNumber,trip]);
  return (
    <tr key={item}>
      <td
        align="left"
        style={{
          position: "sticky",
          left: 0,
          zIndex: 1200,
        }}
        className={"brd_blk name-td headcol"}
      >
        <Chip
          className="nobg"
          label={trip["name"] + " " + trip["surName"]}
          onClick={(e) => {
            handleClick(e);
            setImg(trip["photo"]);
          }}
          avatar={
            <Avatar
              alt="Natacha"
              src={_BASE_URL + "/" + trip["photo"]}
            />
          }
        />
      </td>
      {getUserRow}
    </tr>
  );
};

const TripRow = React.memo(TripRowComponent);

export default TripRow;