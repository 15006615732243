import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
const filterInitial = {
  professionals: [],
  branches: [],
  knownbys: [],
  residences: [],
  skills: [],
  workFields: [],
  nationalities: [],
  languages: [],
  vaccinationStatus: false,
  educations: [],
  salaryMin: 0,
  salaryMax: 0,
  ageMin: 0,
  ageMax: 0,
  zipMin: 0,
  zipMax: 0
}
export default function LeftBar(props) {
  const { filterConfig, filterLoading, updateFilter } = props;
  const [filterOptions, setFilter] = useState(filterInitial);
  const [displayFiter, setDisplayFilter] = useState({
    proffesions: [],
    branches: [],
    knownbys: [],
    residences: [],
    skills: [],
    workFields: [],
    nationalities: [],
    languages: [],
    educations: [],
  });
  const [searchBoxVal, setSearchBoxVal] = useState({
    proffesions: "",
    branches: "",
    knownbys: "",
    residences: "",
    skills: "",
    workFields: "",
    nationalities: "",
    languages: "",
    educations: "",
  });
  const [viewMoreData, setViewMoreData] = useState({
    proffesions: false,
    branches: false,
    knownbys:false,
    residences: false,
    skills: false,
    workFields: false,
    nationalities: false,
    languages: false,
    educations: false,
  });
  useEffect(() => {
    console.log('displayFiter changed', displayFiter);
  }, [displayFiter])
  useEffect(() => {
    console.log("filter change", props.appliedFilter)
    if (typeof props.appliedFilter.id == 'undefined')
      setFilter(filterInitial);
    try {
      if (typeof props.appliedFilter.search != "undefined") {
        const params = JSON.parse(props.appliedFilter.search);
        if (params) {
          let tempFileter = filterInitial;
          if (params.filterOptions && params.filterOptions.length > 0) {
            Object.key(params.filterOptions).forEach(key => {
              tempFileter[key] = params.filterOptions[key];
            });
          }
          setFilter(tempFileter)
        }
      }
    } catch (err) {

    }
  }, [props.appliedFilter]);

  useEffect(() => {
    if (!filterLoading && filterConfig) {
      setDisplayFilter({
        proffesions: filterConfig.proffesions ? filterConfig.proffesions.slice(0, 10) : [],
        branches: filterConfig.branches ? filterConfig.branches.slice(0, 10) : [],
        knownbys: filterConfig.knownbys ? filterConfig.knownbys.slice(0, 10) : [],
        skills: filterConfig.skills ? filterConfig.skills.slice(0, 10) : [],
        workFields: filterConfig.workFields ? filterConfig.workFields.slice(0, 10) : [],
        nationalities: filterConfig.nationalities ? filterConfig.nationalities.slice(0, 10) : [],
        languages: filterConfig.languages ? filterConfig.languages.slice(0, 10) : [],
        educations: filterConfig.educations ? filterConfig.educations.slice(0, 10) : [],
        residences: filterConfig.residences ? filterConfig.residences.slice(0, 10) : [],
      });
    }
  }, [filterLoading]);
  useEffect(() => {
    if (!filterLoading) {
      Object.keys(viewMoreData).forEach(data => {
        updateSearchVal(searchBoxVal[data], data);
      })

    }
  }, [viewMoreData])
  const resetFilter = () => {
    const flt = {
      professionals: [],
      branches: [],
      knownbys:[],
      residences: [],
      skills: [],
      workFields: [],
      nationalities: [],
      languages: [],
      vaccinationStatus: false,
      educations: [],
      salaryMin: 0,
      salaryMax: 0,
      ageMin: 0,
      ageMax: 0,
      zipMin: 0,
      zipMax: 0
    }

    setFilter(flt);
    updateFilter && updateFilter(flt);
  }

  const handleFilter = (value, type) => () => {
    let filter = { ...filterOptions };
    if (type == 'professionals') {
      if (filter.professionals.indexOf(value) == -1)
        filter.professionals.push(value);
      else
        filter.professionals.splice(filter.professionals.indexOf(value), 1);
    }
    if (type == 'skills') {
      if (filter.skills.indexOf(value) == -1)
        filter.skills.push(value);
      else
        filter.skills.splice(filter.skills.indexOf(value), 1);
    }
    if (type == 'branches') {
      if (filter.branches.indexOf(value) == -1)
        filter.branches.push(value);
      else
        filter.branches.splice(filter.branches.indexOf(value), 1);
    }
    if (type == 'knownbys') {
      if (filter.knownbys.indexOf(value) == -1)
        filter.knownbys.push(value);
      else
        filter.knownbys.splice(filter.knownbys.indexOf(value), 1);
    }
    if (type == 'residences') {
      if (filter.residences.indexOf(value) == -1)
        filter.residences.push(value);
      else
        filter.residences.splice(filter.residences.indexOf(value), 1);
    }
    if (type == 'workFields') {
      if (filter.workFields.indexOf(value) == -1)
        filter.workFields.push(value);
      else
        filter.workFields.splice(filter.workFields.indexOf(value), 1);
    }
    if (type == 'languages') {
      if (filter.languages.indexOf(value) == -1)
        filter.languages.push(value);
      else
        filter.languages.splice(filter.languages.indexOf(value), 1);
    }
    if (type == 'nationality') {
      if (filter.nationalities.indexOf(value) == -1)
        filter.nationalities.push(value);
      else
        filter.nationalities.splice(filter.nationalities.indexOf(value), 1);
    }
    if (type == 'educations') {
      if (filter.educations.indexOf(value) == -1)
        filter.educations.push(value);
      else
        filter.educations.splice(filter.educations.indexOf(value), 1);
    }
    if (type == 'vaccinationStatus') {

      filter.vaccinationStatus = value;
    }
    setFilter(filter);
    updateFilter && updateFilter(filter);
  }
  const updateMinSalary = (e) => {
    let filter = { ...filterOptions };
    filter.salaryMin = e.target.value;
    setFilter(filter);
    updateFilter && updateFilter(filter);
  }
  const updateMaxSalary = (e) => {
    let filter = { ...filterOptions };
    filter.salaryMax = e.target.value;
    setFilter(filter);
    updateFilter && updateFilter(filter);
  }

  const updateMinAge = (e) => {
    let filter = { ...filterOptions };
    filter.ageMin = e.target.value;
    setFilter(filter);
    updateFilter && updateFilter(filter);
  }
  const updateMaxAge = (e) => {
    let filter = { ...filterOptions };
    filter.ageMax = e.target.value;
    setFilter(filter);
    updateFilter && updateFilter(filter);
  }

  const updateMinZip = (e) => {
    let filter = { ...filterOptions };
    filter.zipMin = e.target.value;
    setFilter(filter);
    updateFilter && updateFilter(filter);
  }
  const updateMaxZip = (e) => {
    let filter = { ...filterOptions };
    filter.zipMax = e.target.value;
    setFilter(filter);
    updateFilter && updateFilter(filter);
  }
  const updateSearchVal = (value, item) => {
    console.log("Value :" + value + " Item :" + item)
    const mapField = {
      proffesions: 'proffesion',
      branches: 'branch',
      knownbys:'name',
      skills: 'skill',
      workFields: 'work',
      nationalities: 'nation',
      languages: 'language',
      educations: 'education',
      residences: 'residence',
    };
    const searchVal = value.toLowerCase();
    let tempItems = filterConfig[item].filter(obj => {

      let itemText = obj[mapField[item]].trim().toLowerCase();
      return itemText.indexOf(searchVal) == 0;
    });
    if (!viewMoreData[item]) {
      tempItems = tempItems.slice(0, 10);
    }
    console.log("mapFieldmapFieldmapField => ", viewMoreData[item], tempItems);
    const temsSearchValues = { ...searchBoxVal };
    temsSearchValues[item] = value;
    setSearchBoxVal(temsSearchValues);
    const tempDisplayFilter = { ...displayFiter };
    displayFiter[item] = tempItems;
    setDisplayFilter(displayFiter);
  }
  const handleViewMore = (item) => {
    const tempViewMore = { ...viewMoreData };
    tempViewMore[item] = !tempViewMore[item];
    console.log("mapFieldmapFieldmapField => ", tempViewMore[item]);
    setViewMoreData(tempViewMore);
  }
  return (
    <div className={"acclist"}>
      <p><span className={"filterhead"}>Filters </span>  <span className={'filterclear'} onClick={resetFilter} > Clear </span></p>

      {props.filterLoading ? <div className="loaderShow">Loading..</div> : null}

      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={"accorhead"}>Branch</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className={'leftSearch'} >
              <input type="text"  placeholder="Search" value={searchBoxVal.branches} 
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'branches');
              }}  />
            </ListItem>
            {!filterLoading && filterConfig.branches && displayFiter.branches.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} 
                disabled={props.resumeLoading}
                onClick={handleFilter(item.id, 'branches')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.branches.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.branch} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}

          </List>
          <div className="viweMore">
            {viewMoreData['branches'] ?
              <span onClick={e => handleViewMore('branches')}  >View less</span>
              : <span onClick={e => handleViewMore('branches')}  >View more</span>}
          </div>
        </AccordionDetails>
      </Accordion>



      
      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={"accorhead"}>Profession</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List  >
            <ListItem className={'leftSearch'}>
              <input type="text"  placeholder="Search" value={searchBoxVal.proffesions} 
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'proffesions');
              }} />
            </ListItem>
            {!filterLoading && filterConfig.proffesions && displayFiter.proffesions.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'professionals')} dense>
                  <ListItemIcon>

                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.professionals.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.proffesion} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
           
          </List><div className="viweMore">
              {viewMoreData['proffesions'] ?
                <span onClick={e => handleViewMore('proffesions')}  >View less</span>
                : <span onClick={e => handleViewMore('proffesions')}  >View more</span>}
            </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={"accorhead"}>Field of work</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className={'leftSearch'}>
              <input type="text"  placeholder="Search" value={searchBoxVal.workFields}
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'workFields');
              }} />
            </ListItem>
            {!filterLoading && filterConfig.workFields && displayFiter.workFields.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'workFields')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.workFields.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.work} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
          
          </List><div className="viweMore">
              {viewMoreData['workFields'] ?
                <span onClick={e => handleViewMore('workFields')}  >View less</span>
                : <span onClick={e => handleViewMore('workFields')}  >View more</span>}
            </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={"accorhead"} >Education</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className={'leftSearch'}>
              <input type="text"  placeholder="Search" value={searchBoxVal.educations} 
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'educations');
              }} />
            </ListItem>
            {!filterLoading && filterConfig.educations && displayFiter.educations.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'educations')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.educations.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.education} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
            
          </List>
          <div className="viweMore">
              {viewMoreData['educations'] ?
                <span onClick={e => handleViewMore('educations')}  >View less</span>
                : <span onClick={e => handleViewMore('educations')}  >View more</span>}
            </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={"accorhead"}>Skill</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List  >
            <ListItem className={'leftSearch'}>
              <input type="text"  placeholder="Search" value={searchBoxVal.skills} 
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'skills');
              }} />
            </ListItem>
            {!filterLoading && filterConfig.skills && displayFiter.skills.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'skills')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.skills.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.skill} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
            
          </List><div className="viweMore">
              {viewMoreData['educations'] ?
                <span onClick={e => handleViewMore('skills')}  >View less</span>
                : <span onClick={e => handleViewMore('skills')}  >View more</span>}
            </div>
        </AccordionDetails>
      </Accordion>


      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={"accorhead"}>Place of Residence</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className={'leftSearch'}>
              <input type="text"  placeholder="Search" value={searchBoxVal.residences} 
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'residences');
              }} />
            </ListItem>
            {!filterLoading && filterConfig.branches && displayFiter.residences.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'residences')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.residences.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.residence} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
           
          </List><div className="viweMore">
              {viewMoreData['residences'] ?
                <span onClick={e => handleViewMore('residences')}  >View less</span>
                : <span onClick={e => handleViewMore('residences')}  >View more</span>}
            </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={"accorhead"}>Knownby</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className={'leftSearch'} >
              <input type="text"  placeholder="Search" value={searchBoxVal.knownbys} 
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'knownbys');
              }}  />
            </ListItem>
            {!filterLoading && filterConfig.knownbys && displayFiter.knownbys.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'knownbys')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.knownbys.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.name} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}

          </List>
          <div className="viweMore">
            {viewMoreData['knownbys'] ?
              <span onClick={e => handleViewMore('knownbys')}  >View less</span>
              : <span onClick={e => handleViewMore('knownbys')}  >View more</span>}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={"accorhead"}>Nationality</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className={'leftSearch'}>
              <input type="text"  placeholder="Search" value={searchBoxVal.nationalities}
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'nationalities');
              }} />
            </ListItem>
            {!filterLoading && filterConfig.nationalities && displayFiter.nationalities.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'nationality')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.nationalities.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.nation} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
          
          </List> <div className="viweMore">
              {viewMoreData['nationalities'] ?
                <span onClick={e => handleViewMore('nationalities')}  >View less</span>
                : <span onClick={e => handleViewMore('nationalities')}  >View more</span>}
            </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={"accorhead"}>Languages</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem className={'leftSearch'}>
              <input type="text"  placeholder="Search" value={searchBoxVal.languages} 
                              disabled={props.resumeLoading}

              onChange={e => {
                updateSearchVal(e.target.value, 'languages');
              }} />
            </ListItem>
            {!filterLoading && filterConfig.languages && displayFiter.languages.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
              >
                <ListItemButton role={undefined} onClick={handleFilter(item.id, 'languages')} dense>
                  <ListItemIcon>
                    <Checkbox className={"accche"}
                      edge="start"
                      checked={filterOptions.languages.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.id }}
                      disabled={props.resumeLoading}

                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.language} className={"acccolor"} />
                </ListItemButton>
              </ListItem>
            ))}
            
          </List> <div className="viweMore">
              {viewMoreData['languages'] ?
                <span onClick={e => handleViewMore('languages')}  >View less</span>
                : <span onClick={e => handleViewMore('languages')}  >View more</span>}
            </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={"accorhead"}>Salary Range</Typography>
        </AccordionSummary>
        <AccordionDetails >

          {!filterLoading ?
            <div style={{ display: 'flex' }}>
              <TextField label="Min" size="small" className={"acccolor"} variant="outlined" 
                              disabled={props.resumeLoading}

              onChange={updateMinSalary} value={filterOptions.salaryMin} type="number" />
              <TextField label="Max" size="small"   className={"acccolor"} variant="outlined" 
                              disabled={props.resumeLoading}

              onChange={updateMaxSalary} value={filterOptions.salaryMax} type="number" />
            </div>
            : null
          }
        </AccordionDetails>
      </Accordion>
      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={"accorhead"}>Age Range</Typography>
        </AccordionSummary>
        <AccordionDetails >

          {!filterLoading ?
            <div style={{ display: 'flex' }}>
              <TextField label="Min" size="small"  className={"acccolor"} variant="outlined" 
                              disabled={props.resumeLoading}

              onChange={updateMinAge} value={filterOptions.ageMin} type="number" />
              <TextField label="Max" size="small"  className={"acccolor"} variant="outlined" 
                              disabled={props.resumeLoading}

              onChange={updateMaxAge} value={filterOptions.ageMax} type="number" />
            </div>
            : null
          }
        </AccordionDetails>
      </Accordion>

      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={"accorhead"}>Zipcode Range</Typography>
        </AccordionSummary>
        <AccordionDetails >

          {!filterLoading ?
            <div style={{ display: 'flex' }}>
              <TextField label="Min"  size="small"  className={"acccolor"} variant="outlined" 
                              disabled={props.resumeLoading}

              onChange={updateMinZip} value={filterOptions.zipMin} type="number" />
              <TextField label="Max"  size="small"  className={"acccolor"} variant="outlined" 
                              disabled={props.resumeLoading}

              onChange={updateMaxZip} value={filterOptions.zipMax} type="number" />
            </div>
            : null
          }
        </AccordionDetails>
      </Accordion>

      <Accordion className={"accborder"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={"accorhead"}>Vaccination status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem

              disablePadding
            >
              <ListItemButton role={undefined} 
                              disabled={props.resumeLoading}

              onClick={handleFilter(!filterOptions.vaccinationStatus, 'vaccinationStatus')} dense>
                <ListItemIcon>
                  <Checkbox className={"accche"}
                    edge="start"
                    checked={filterOptions.vaccinationStatus}
                    tabIndex={-1}
                    disableRipple
                    disabled={props.resumeLoading}

                  />
                </ListItemIcon>
                <ListItemText primary="Vaccinated" className={"acccolor"} />
              </ListItemButton>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}