import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import StarRatings from "react-star-ratings";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { _BASE_URL } from "../../../Config/Constants";
import CommentList from "../CommentList";
import Highlighter from "react-highlight-words";
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
const getProfCombained = (proffesion) => {
    const arr = proffesion.map((item) => item.proffesion);
    return arr.join(',');
}
const getEduCombained = (edu) => {
    const arr = edu.map((item) => item.education);
    return arr.join(',');
}
const getWorkCombained = (workFields) => {
    const arr = workFields.map((item) => item.work);
    return arr.join(',');
}
const getLangCombained = (langField) => {
    const arr = langField.map((item) => item.language);
    return arr.join(',');
}
const getSkillsCombained = (skills) => {
    const arr = skills.map((item) => item.skill);
    return arr.join(',');
}
const getKnownCombained = (refFields) => {
    const arr = refFields.map((item) => item.name);
    return arr.join(',');
}
class ViewResume extends Component {
    constructor(props) {
        super(props);
        this.state = { commentText: "",tab:1 };
        this.downloadResume = this.downloadResume.bind(this);
                this.viewResume = this.viewResume.bind(this);

        this.handleCommentAdd = this.handleCommentAdd.bind(this);
        this.downloadRefDoc = this.downloadRefDoc.bind(this);
        this.savePdf = this.savePdf.bind(this);
    }
    viewResume(){
console.log(this.props.resume.resumeFile);
 window.open(_BASE_URL +'/'+ this.props.resume.resume)
    }
    downloadResume() {
        console.log(this.props.resume.resumeFile);
        
        window.open(_BASE_URL + "/FrondEndController/downloadDoc?path=" + this.props.resume.resume+"&doc_name=Resume_"+this.props.resume.name).focus();
    }
    downloadRefDoc(doc) {
        let fileNameWithoutExtension = (doc.title).split('.')[0];
        window.open(_BASE_URL + "/FrondEndController/downloadDoc?path=" + doc.path+"&doc_name="+fileNameWithoutExtension).focus();
       // window.open(_BASE_URL + "/" + doc.path).focus();
    }
    handleCommentAdd() {
        const params = { comment: this.state.commentText, emp_id: this.props.resume.id };
        axios.post(_BASE_URL + '/add-comment', params).
            then((response) => {
                if (response.data.status == "success") {
                    this.setState({ "commentText": "" });
                    this.props.handleViewResume(this.props.resume.id);
                }

            })
            .catch(err => {
                alert("Error failed to add comments");
            })
    }
    savePdf() {
        window.open(_BASE_URL + '/FrondEndController/responsePDF/' + this.props.resume.id).focus();
    }
    render() {
        const { resume, searchTerm,searchArray } = this.props;
        const {tab} =this.state;
        return (<Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={this.props.openViewResume}
            onClose={this.props.closeViewResume}
            className={"view-res-dialog"}
            scroll={"body"}
        >

            <DialogContent className={"form-dialog-view"}>
                {(resume && typeof resume.name != 'undefined') ?
                    <React.Fragment>
                        <Grid container>
                            <Grid item md={6} className={"filterhead dialog-title-elm"}><strong>View Resume</strong></Grid>
                            <Grid item md={4} style={{ textAlign: "right" }} className={"marginresume dialog-title-elm hide-print"}>
                                {/*    <PrintIcon color={'action'} className={"blackcolor action-item"} onClick={() => window.print()} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                <EditIcon color={'action'} onClick={e => this.props.editResume(resume.id)} className={"blackcolor  action-item"} /> &nbsp;&nbsp;&nbsp;&nbsp;
                                <SaveIcon color={'action'} onClick={this.savePdf} className={"blackcolor action-item"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            </Grid>
                            <Grid item md={2} style={{textAlign:"right"}}>
                            <IconButton className="hide-print close-pos close" type="button" sx={{ p: '10px' }} onClick={this.props.closeViewResume} aria-label="search">
                                <CloseIcon className={"blk"} />
                            </IconButton>
                            </Grid>

                        </Grid>
                        <Grid container className="tabHeader">
                            <Grid item sm={12} style={{marginBottom:"5px"}}>
                                <span className={"tabItem "+(tab==1?"selectedTab":"")} onClick={()=>this.setState({tab:1})}>Profesional Details</span>
                                <span className={"tabItem "+(tab==2?"selectedTab":"")}  onClick={()=>this.setState({tab:2})}> Personal Details</span>
                            </Grid>
                        </Grid>
                        
                        {tab==1?<Grid container className={"scollabel"}>
                            <Grid item md={9} className="print-sm1 containerDiv">
                                <Grid container >
                                    <Grid item md={12}><strong>Branch</strong>  : <strong>{resume.branch_res ? resume.branch_res.branch : ''}</strong></Grid>
                                    <Grid item md={6}>Name  : <strong> <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={resume.name + " " + resume.surName} /></strong></Grid>
                                    {resume.dob ?
                                        <Grid item md={6}>Year of birth  : <strong>{resume.dob.split("-")[0]}</strong></Grid>
                                        : null}
                                    <Grid item md={6}>Phone  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={resume.phone} /></strong></Grid>
                                    {resume.alt_mobile ?
                                        <Grid item md={6}>Alternative Phone: <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.alt_mobile} /></strong></Grid>
                                        : null}

                                    {resume.skype ?
                                        <Grid item md={6}>Skype Id  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.skype} /></strong></Grid>
                                        : null}
                                    {resume.emailPrivate ?
                                        <Grid item md={6}>Email (Private)  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.emailPrivate} /></strong></Grid>
                                        : null}
                                    {resume.emailSupervision ?
                                        <Grid item md={6}>Email (Supervision)  : <strong><Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.emailSupervision} /></strong></Grid>
                                        : null}
                                    {resume.nation ?
                                        <Grid item md={6}>Nationality  : <strong>{resume.nation ? <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.nation.nation} /> : ''}</strong></Grid>
                                        : null}
                                    {resume.residence ?
                                        <Grid item md={6}>Place of Residence  : <strong>{resume.residences ? <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.residences.residence} /> : ""}</strong></Grid>
                                        : null}
                                    {resume.zipcode ?
                                        <Grid item md={6}>Full address: <strong>{resume.zipcode}</strong></Grid>
                                        : null}

                                    {resume.languages.length ?
                                    <Grid item md={6}>Language  : <strong>{resume.languages ? <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getLangCombained(resume.languages)} /> : ''}</strong></Grid>
                                        :null}
                                    {resume.educations.length ?
                                    <Grid item md={6}>Education  : <strong>{resume.educations ? <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getEduCombained(resume.educations)} /> : ''}</strong></Grid>
                                        :null}

                                    {resume.proffesions.length?
                                    <Grid item md={6}>Profession  : <strong>{resume.proffesions ? <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getProfCombained(resume.proffesions)} /> : ''}</strong></Grid>
                                        :null}
                                    {resume.workFields.length ?
                                    <Grid item md={6}>Field of Work  : <strong>{resume.workFields ? <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getWorkCombained(resume.workFields)} /> : ''}</strong></Grid>
                                        : null }
                                     {resume.skills.length ?                                       
                                    <Grid item md={6}>Special Skills  : <strong>{resume.skills ? <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={getSkillsCombained(resume.skills)} /> : ''}</strong></Grid>
                                        : null }
                                     {resume.salary>0 ? 
                                    <Grid item md={6}>Salary (Rate)  : <strong>{resume.salary}</strong></Grid>
                                    : null }
                                       {resume.refFields.length ? 
                                      <Grid item md={6}>Known By : <strong>{resume.refFields ? getKnownCombained(resume.refFields) : ''}</strong></Grid>
                                      :null}
                                         {resume.pmName ?                                       
                                    <Grid item md={6}>Project Manager  : <strong>{resume.pmName}</strong></Grid>
                                    :null }
                                    {resume.pmaName ? 
                                     <Grid item md={6}>Project Coordinator  : <strong>{resume.pmaName}</strong></Grid>
                                    :null}

                                    
                                </Grid>
                                <Grid container >
                                    <Grid item md={12}>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.isFreelance == 'Yes'} />}
                                                    label="Freelance"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.hasEupassport == 'Yes'} />}
                                                    label="European passport"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.isVaccinated == 'Yes'} />}
                                                    label="Vaccinated"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox checked={resume.isIntern == 'Yes'} />}
                                                    label="Intern"
                                                    labelPlacement="end"
                                                    className={"colorBlack"}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item md={3} className="print-sm2">
                                <img src={resume.img} width={150} />
                                <br />
                                <br />
                                <lable>Rating</lable>
                                <StarRatings
                                    rating={parseInt(resume.rating)}
                                    starRatedColor="#e7af00"
                                    numberOfStars={5}
                                    starDimension={"25px"}
                                    starSpacing={"5px"}
                                    starHoverColor={"yellow"}
                                    name='rating' />

                                <br />
                                <br />
                                {resume.resume != "" ?
                                    <Button size="small" className={"viewresbtn hide-print"} 
                                    onClick={this.downloadResume} startIcon={<AttachFileIcon 
                                        />} 
                                    variant="outlined">Download Resume</Button>
                                    : null}
                                    <br />
                                <br />
                                    {resume.resume !== "" && resume.resume.endsWith(".pdf") ? 

        <Button size="small" className={"viewresbtn hide-print"} 
            onClick={this.viewResume} startIcon={<VisibilityIcon />} 
            variant="outlined">
            View Resume
        </Button> :null }
{resume.resumeList != [] && Array.isArray(resume.resumeList) && resume.resumeList.length>0 ?   
<>
<p>
<strong>Documents</strong>
</p>
</>
 :null}  
                                {resume.resumeList != [] ?                                
                                    resume.resumeList.map((refDoc) =>
                                        <p className="p-chip" onClick={e => this.downloadRefDoc(refDoc)}>{refDoc.title}</p>
                                    ) : null}

                            </Grid>


                            {resume.exp_experience ?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Experience</h4>

                                    <div className={"vpuplecor"} style={{ whiteSpace: 'pre-wrap' }}  >
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_experience} />
                                    </div>


                                </Grid>
                                : null}

                            {resume.exp_education?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Education</h4>
                                    <div className={"vpuplecor"} style={{ whiteSpace: 'pre-wrap' }}>
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_education} />
                                    </div>

                                </Grid>
                                : null}

                            {resume.exp_courses?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Courses and certificates</h4>
                                    <div className={"vpuplecor"} style={{ whiteSpace: 'pre-wrap' }}>
                                      
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_courses} />
                                    </div>
                                </Grid>
                                : null}


                            {resume.exp_profile  ?
                                <Grid item md={12} className={"list-sub-container"}>

                                    <h4 className="sub-h4">Profile</h4>
                                    <p className={"vpuplecor"}>
                                        <Highlighter highlightClassName="YourHighlightClass"
                                            searchWords={searchArray}
                                            autoEscape={true} textToHighlight={resume.exp_profile} />
                                    </p>

                                </Grid>
                                : null}


{resume.references &&<Grid item md={12} className={"list-sub-container"}>

                                <h4 className="sub-h4">Reference</h4>
                                { resume.references.map((item) =>
                                    <p className={"vpuplecor"}>
                                        {item.reference}
                                    </p>
                                )}

                            </Grid>}

                            <Grid item md={12} className={"list-sub-container viewListComment"}>
                                <CommentList resumeData={resume} empId={resume.id} />
                            </Grid>
                        </Grid>
                        :<>
                        <Grid item md={6}>Father  : {resume.father ?
 <strong> 
 <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={resume.father} /></strong>
                                        :<strong>Data not found</strong>}
                                        </Grid>

                        {resume.mother ?
                        <Grid item md={6}>Mother  : <strong> <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={resume.mother} /></strong></Grid>
                        :null}
                        {resume.spouse ?
                        <Grid item md={6}>spouse  : <strong> <Highlighter highlightClassName="YourHighlightClass"
                                        searchWords={searchArray}
                                        autoEscape={true} textToHighlight={resume.spouse} /></strong></Grid>
                        :null}
                        {resume.emergency_no ?
                        <Grid item md={6}>Emergency Contact Number  : <strong> <Highlighter highlightClassName="YourHighlightClass"
                                                                searchWords={searchArray}
                                                                autoEscape={true} textToHighlight={resume.emergency_no} /></strong></Grid>
                        :null}
                                                             {resume.passport ? 
                                    <Grid item md={6}>Passport Details  : <strong> {resume.passport}</strong></Grid>
                                    :null}
                                    {resume.passportExDate ? 
                                    <Grid item md={6}>Passport Expiry Date: <strong> {resume.passportExDate}</strong></Grid>
                                    :null}
                                    {resume.a1ExDate ? 
                                    <Grid item md={6}>A1 Expiry Date : <strong> {resume.a1ExDate}</strong></Grid>
                                    :null}

                       
                                                                                                        </>
                        }
                    </React.Fragment>
                    :
                    <div style={{ minHeight: '300px', textAlign: 'center', paddingTop: '10%', width: '100%' }}>
                        <CircularProgress />
                    </div>
                }
            </DialogContent>
        </Dialog>
        )
    }
}
export default ViewResume;